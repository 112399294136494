import React from 'react';
import { TextArea } from './text-area';
import { Markdown } from './markdown';

interface Props {
  name: string;
  markdown: string | undefined;
  errorMessage?: string;
}

const BaseMarkdownEditor = (
  { name, markdown, errorMessage }: Props,
  ref: React.Ref<HTMLTextAreaElement>,
): React.ReactElement => {
  const columnClasses = 'w-1/2 flex flex-col';

  return (
    <div className="flex space-x-5">
      <div className={columnClasses}>
        <TextArea
          rows={15}
          ref={ref}
          label="Markdown"
          placeholder="Start typing..."
          name={name}
          errorMessage={errorMessage}
        />
      </div>
      <div className={columnClasses}>
        <h3 className="heading-sm mb-3">Preview</h3>
        <div className="border border-gray-400 rounded-md bg-gray-100">
          <Markdown
            src={markdown ?? ''}
            className="h-[23.5rem] px-3 py-2 overflow-y-scroll tracking-tight"
          />
        </div>
      </div>
    </div>
  );
};

export const MarkdownEditor = React.forwardRef<HTMLTextAreaElement, Props>(
  BaseMarkdownEditor,
);
