import { DefaultConfig } from './types';
import { defaultConfig } from './default';

export const defaultPilot: DefaultConfig = {
  ...defaultConfig,
  brand: 'pilot',
  enableDeliveryBufferInProduct: true,
  showDeliveryInstructions: true,
  syncConsultationsEnabled: true,
  medicareEnabled: true,
  shopEnabled: true,
};
