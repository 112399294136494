import { Modal } from 'components/modal';
import { useNotifications } from 'notifications';
import React from 'react';
import { RequestReview } from './main';
import { ProblemType } from 'graphql/types';
import { Customer } from 'pages/customer/types';

type RequestReviewButtonProps = {
  problemType: ProblemType;
  customer: Customer;
};

export const RequestReviewButton: React.FC<RequestReviewButtonProps> = (
  requestReviewProps,
) => {
  const [showModal, setShowModal] = React.useState(false);
  const showNotification = useNotifications();

  return (
    <>
      <button
        type="button"
        className="inline-flex disabled:cursor-not-allowed disabled:opacity-25 items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white focus:outline-none focus:ring-blue focus:border-blue-300 hover:bg-gray-100 active:text-gray-800 active:bg-white transition duration-150 ease-in-out"
        onClick={(): void => {
          setShowModal(true);
        }}
      >
        ❗️ Request Review
      </button>
      {showModal && (
        <Modal
          show
          onClose={(): void => setShowModal(false)}
          isAutoOverflow={false}
          width="w-5/6"
        >
          <RequestReview
            onCancel={(): void => setShowModal(false)}
            onCompleted={(): void => {
              setShowModal(false);
              showNotification({
                type: 'success',
                message: 'Successfully requested review',
              });
            }}
            {...requestReviewProps}
          />
        </Modal>
      )}
    </>
  );
};
