import clsx from 'clsx';
import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Loading } from './loading';

export function ContextMenu(props: {
  copy: string;
  options: { copy: string; onClick: () => void }[];
  loading?: boolean;
}): JSX.Element {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className="text-sm"
      onMouseEnter={(): void => setVisible(true)}
      onMouseLeave={(): void => setVisible(false)}
    >
      <button
        className={clsx(
          'relative bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-400 font-medium rounded-lg px-4 py-2.5 text-center inline-flex items-center',
          { 'bg-gray-300': visible || !props.loading },
        )}
        disabled={props.loading}
        type="button"
      >
        <span
          className={clsx('', {
            visible: !props.loading,
            invisible: props.loading,
          })}
        >
          {props.copy}&nbsp;
        </span>
        <FaChevronDown />
        <div
          className={clsx('absolute top-1/3 inset-x-1/3', {
            visible: props.loading,
            invisible: !props.loading,
          })}
        >
          <Loading />
        </div>
      </button>
      <div
        className={clsx(
          'z-10 cursor-pointer absolute bg-white divide-y divide-gray-100 rounded-lg shadow',
          { hidden: !visible || props.loading },
        )}
      >
        <ul>
          {props.options.map((o) => (
            <li key={o.copy}>
              <a
                onClick={(): void => {
                  o.onClick();
                  setVisible(false);
                }}
                className="block px-4 py-2.5 hover:bg-gray-100"
              >
                {o.copy}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
