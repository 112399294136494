import { gql, useMutation } from '@apollo/client';
import {
  FileCreateWithoutAvatarOfInput,
  User,
  UserCreateInput,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { Fields } from './types';
import HealthCoachForm from './components/health-coach-form';

const createHealthCoachMutation = gql`
  mutation createOneHealthCoach(
    $firstName: String
    $lastName: String
    $bio: String
    $email: String!
    $avatar: FileCreateNestedOneWithoutAvatarOfInput
  ) {
    createOneUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        role: HEALTH_COACH
        bio: $bio
        avatar: $avatar
      }
    ) {
      id
    }
  }
`;

const mapFieldsToGraphQLVariables = async (
  fields: Fields,
): Promise<UserCreateInput> => {
  const variables: UserCreateInput = {
    firstName: fields.firstName,
    lastName: fields.lastName,
    bio: fields.bio,
    email: fields.email,
  };

  if (fields.avatar) {
    variables.avatar = {
      create: JSON.parse(fields.avatar) as FileCreateWithoutAvatarOfInput,
    };
  }

  return variables;
};

const CreateHealthCoach = (): React.ReactElement => {
  const showNotification = useNotifications();
  const history = useHistory();

  const [createHealthCoach, { loading }] = useMutation<{ createOneUser: User }>(
    createHealthCoachMutation,
    {
      onCompleted: async ({ createOneUser: healthCoach }) => {
        showNotification({
          type: 'success',
          message: 'Health Coach created successfully',
        });
        history.push(`${routes.healthCoaches}/${healthCoach.id}`);
      },
      onError: () =>
        showNotification({
          type: 'error',
          message: 'There was an error attempting to create this health coach',
        }),
    },
  );

  const onSubmit = async (fields: Fields): Promise<void> => {
    await createHealthCoach({
      variables: await mapFieldsToGraphQLVariables(fields),
    });
  };

  return (
    <HealthCoachForm onSubmit={onSubmit} submitting={loading} type="create" />
  );
};

export default CreateHealthCoach;
